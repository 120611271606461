import React from 'react';
import ReactDOM from 'react-dom/client';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';

import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel, ElementsPanel } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { createStore } from 'polotno/model/store';
// import { QrSection, getQR } from './QrSection';

import axios from 'axios'

import "rsuite/dist/rsuite.min.css";

import { Dropdown } from 'rsuite';

import { registerNextDomDrop } from 'polotno/config';

import {
    FontFamilyInput,
    FontSizeInput,
    FontStyleGroup,
    FontColorInput,
    SpacingInput,
  } from 'polotno/toolbar/text-toolbar';

import {
    ImageToolbar
} from 'polotno/toolbar/image-toolbar';

import {
    SvgToolbar
} from 'polotno/toolbar/svg-toolbar';

import { observer } from 'mobx-react-lite';
import { Button, Popover, H3, NumericInput, Navbar, Alignment } from '@blueprintjs/core';

import { Popover2 } from '@blueprintjs/popover2';

import ColorPicker from 'polotno/toolbar/color-picker';
import { unstable_registerToolbarComponent, unstable_registerShapeComponent, unstable_registerShapeModel } from 'polotno/config';
import { useSnap } from 'polotno/canvas/use-snap';

import { Rect, Circle, Layer, Group, Star, Line } from 'react-konva';

// import all default sections
import { DEFAULT_SECTIONS } from 'polotno/side-panel';

let RAVU_API_URL = (document.domain == 'localhost') ? 'http://localhost:8001' : "https://api.leads.ravu.me";
let RAVU_VUE_URL = (document.domain == 'localhost') ? 'http://localhost:8085' : "https://app.localleadsniper.com";

/* RECT */
unstable_registerShapeModel({
    type: 'rect',
    width: 150,
    height: 150,
    fill: "#CCCCCC"
});

// every components has three main props - onClick, element and store
export const RectElement = observer(({ onClick, element, store }) => {
    const ref = React.useRef();
    // useSnap - is utility hook that automatically enables snapping
    const { onDragMove, onDragEnd } = useSnap(ref);

    const handleChange = (e) => {
        const node = e.currentTarget;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        // Konva.Transformer is changing scale by default
        // we don't need that, so we reset it back to 1.
        node.scaleX(1);
        node.scaleY(1);
        element.set({
          x: node.x(),
          y: node.y(),
          rotation: e.target.rotation(),
          width: element.width * scaleX,
          height: element.height * scaleY,
        });
    };

    return (
      <Rect
        ref={ref}
        name="element"
        x={element.x}
        y={element.y}
        width={element.width}
        height={element.height}
        fill={element.fill}
        draggable={!element.locked}
        rotation={element.rotation}
        id={element.id}
        onDragStart={() => {
            const isSelected = store.selectedElements.find((e) => e === element);
            if (!isSelected) {
                store.selectElements([element.id]);
        }
        }}
        onDragMove={(e) => {
            onDragMove(e);
            handleChange(e);
        }}
        onDragEnd={(e) => {
            onDragEnd(e);
        }}
        onClick={() => {
            onClick();
        }}
        onTap={() => {
            onClick();
        }}
        onTransform={handleChange}
      />
    );
  });
  
unstable_registerShapeComponent('rect', RectElement);

const RectToolbar = observer(({ store }) => {
    const element = store.selectedElements[0];
  
    return (
        <div className="full-width">
            <div className="float-left lf-color-picker-wrapper" style={{paddingBottom: '10px'}}>
                <ColorPicker
                onChange={(fill) =>
                    element.set({
                    fill,
                    })
                }
                value={element.fill}
                />
            </div>
        </div>
    );
  });
  
unstable_registerToolbarComponent('rect', RectToolbar);
/* END RECT */

/* CIRCLE */
unstable_registerShapeModel({
    type: 'circle',
    fill: "#CCCCCC",
    radius: 50
});

// every components has three main props - onClick, element and store
export const CircleElement = observer(({ onClick, element, store }) => {
    const ref = React.useRef();
    // useSnap - is utility hook that automatically enables snapping
    const { onDragMove, onDragEnd } = useSnap(ref);

    const handleChange = (e) => {
        const node = e.currentTarget;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        // Konva.Transformer is changing scale by default
        // we don't need that, so we reset it back to 1.
        node.scaleX(1);
        node.scaleY(1);
        element.set({
          x: node.x(),
          y: node.y(),
          rotation: e.target.rotation(),
          radius: element.radius * scaleX
        });
    };

    return (
        <Circle
            name="element"
            x={element.x}
            y={element.y}
            radius={element.radius}
            fill={element.fill}
            rotation={element.rotation}
            draggable={!element.locked}
            id={element.id}
            onDragStart={() => {
                const isSelected = store.selectedElements.find((e) => e === element);
                if (!isSelected) {
                    store.selectElements([element.id]);
            }
            }}
            onDragMove={(e) => {
                onDragMove(e);
                handleChange(e);
            }}
            onDragEnd={(e) => {
                onDragEnd(e);
            }}
            onClick={() => {
                onClick();
            }}
            onTap={() => {
                onClick();
            }}
            onTransform={handleChange}
        />
    );
  });
  
unstable_registerShapeComponent('circle', CircleElement);

const CircleToolbar = observer(({ store }) => {
    const element = store.selectedElements[0];
  
    return (
        <div className="full-width">
            <div className="float-left lf-color-picker-wrapper" style={{paddingBottom: '10px'}}>
                <ColorPicker
                onChange={(fill) =>
                    element.set({
                    fill,
                    })
                }
                value={element.fill}
                />
            </div>
        </div>
    );
  });
  
unstable_registerToolbarComponent('circle', CircleToolbar);
/* END LINE */

// create store
const store = createStore({
  // this is a demo key just for that project
  // (!) please don't use it in your projects
  // to create your own API key please go here: https://polotno.dev/cabinet
  key: 'nFA5H9elEytDyPyvKL7T',
  // you can hide back-link on a paid licence
  // but it will be good if you can keep it for Polotno project support
  showCredit: true,
});

// just change the pages size
store.setSize(600, 800, false);

// add to global namespace for debugging
window.store = store;

// add page and element instantly
store.addPage();

// console.log('zoom is', store.scale);

store.setScale(1);

// we will have just two sections
const sections = [...DEFAULT_SECTIONS];

// store.activePage.addElement({
//     type: 'line'
// });

function addToken(token) {
    let selectedImageElement = store.selectedElements[0];

    let newText = selectedImageElement.text + token;

    selectedImageElement.set({ text: newText });
    document.querySelector("#lf-text-field-text").value = newText;
}

function addTokenRating(token) {
    document.querySelector("#lf-text-field-text").value = token;
    // document.querySelector("#lf-text-field-text").trigger("change");

    var event = new Event('input', { bubbles: true });
    document.querySelector("#lf-text-field-text").dispatchEvent(event);
}

const TextToolbar = observer(({ store }) => {
  const element = store.selectedElements[0];

  let popoverContent=()=>{
      return(
          <div>
          <Popover2>
              <Dropdown.Menu >
                  <Dropdown.Item onClick={() => addToken('[business.business_name]')}>[business.business_name]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.rating]')}>[business.rating]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.reviews]')}>[business.reviews]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.formatted_phone_number]')}>[business.formatted_phone_number]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.international_phone_number]')}>[business.international_phone_number]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.phone_number_type]')}>[business.phone_number_type]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.carrier]')}>[business.carrier]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.address]')}>[business.address]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.website]')}>[business.website]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.quote]')}>[business.quote]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.chat]')}>[business.chat]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.claimed]')}>[business.claimed]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[business.posts]')}>[business.posts]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.business_name]')}>[competitor.business_name]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.rating]')}>[competitor.rating]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.reviews]')}>[competitor.reviews]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.formatted_phone_number]')}>[competitor.formatted_phone_number]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.international_phone_number]')}>[competitor.international_phone_number]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.phone_number_type]')}>[competitor.phone_number_type]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.carrier]')}>[competitor.carrier]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.address]')}>[competitor.address]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.website]')}>[competitor.website]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.quote]')}>[competitor.quote]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.chat]')}>[competitor.chat]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.claimed]')}>[competitor.claimed]</Dropdown.Item>
                  <Dropdown.Item onClick={() => addToken('[competitor.posts]')}>[competitor.posts]</Dropdown.Item>
              </Dropdown.Menu>
          </Popover2>
          </div>
    );
  }

  return (
    <div>
        <div className="full-width lf-text-input-wrapper">
            <div className="float-right" style={{marginTop: '-36px', marginBottom: '0px', marginRight: '0px'}}>
                <Popover2 content={popoverContent()} placement="left">
                    <a href="#">+ Add Token</a>
                </Popover2>
            </div>
            <input id="lf-text-field-text" type="text" className="lf-text-field" defaultValue={element.text}
                onChange={(e) => {
                    element.set({ text: document.querySelector("#lf-text-field-text").value });
                }}

                onKeyUp={(e) => {
                    element.set({ text: document.querySelector("#lf-text-field-text").value });
                }}
            />
        </div>
        <div className="full-width lf-font-family-input-wrapper">
            <FontFamilyInput store={store} elements={store.selectedElements} />
        </div>
        <div className="full-width">
            <div className="float-left lf-color-picker-wrapper">
                <ColorPicker
                    value={element.fill}
                    onChange={(fill) =>
                    element.set({
                        fill,
                    })
                    }
                    store={store}
                />
            </div>
            
            <div className="float-left lf-font-size-input-wrapper">
                <FontSizeInput store={store} elements={store.selectedElements} />
            </div>
        </div>
        <div className="full-width">
            <div className="float-left lf-font-style-wrapper">
                <FontStyleGroup store={store} elements={store.selectedElements} />
            </div>
            <div className="float-left lf-spacing-wrapper">
                <SpacingInput store={store} elements={store.selectedElements} />
            </div>
        </div>
    </div>

  );
});

const CustomSvgToolbar = observer(({ store }) => {
    const element = store.selectedElements[0];
    let popoverContent=()=>{
        return(
            <div>
            <Popover2>
                <Dropdown.Menu >
                    <Dropdown.Item onClick={() => addTokenRating('[business.rating]')}>[business.rating]</Dropdown.Item>
                    <Dropdown.Item onClick={() => addTokenRating('[competitor.rating]')}>[competitor.rating]</Dropdown.Item>
                </Dropdown.Menu>
            </Popover2>
            </div>
      );
    }
  
    return (
      <div>
          <div className="full-width lf-text-input-wrapper">
              <div className="float-right" style={{marginTop: '-36px', marginBottom: '0px', marginRight: '0px'}}>
                  <Popover2 content={popoverContent()} placement="bottom">
                      <a href="#">+ Add Token</a>
                  </Popover2>
              </div>
              <input id="lf-text-field-text" type="text" className="lf-text-field" defaultValue={element.custom.rating}
                  onInput={(e) => {
                    let ratingNr = document.querySelector("#lf-text-field-text").value;
                    element.set({ custom: { rating: ratingNr } });
                  }}

                  onChange={(e) => {

                      let ratingNr = document.querySelector("#lf-text-field-text").value;
                      let rating1Color = "#E8DD6F";
                      let rating2Color = "#E8DD6F";
                      let rating3Color = "#E8DD6F";
                      let rating4Color = "#E8DD6F";
                      let rating5Color = "#E8DD6F";

                      if (ratingNr < 2) {
                        rating2Color = "#E6E6E6";
                        rating3Color = "#E6E6E6";
                        rating4Color = "#E6E6E6";
                        rating5Color = "#E6E6E6";
                      }


                      if (ratingNr < 3) {
                        rating3Color = "#E6E6E6";
                        rating4Color = "#E6E6E6";
                        rating5Color = "#E6E6E6";
                      }


                      if (ratingNr < 4) {
                        rating4Color = "#E6E6E6";
                        rating5Color = "#E6E6E6";
                      }


                      if (ratingNr < 5) {
                        rating5Color = "#E6E6E6";
                      }

                      element.set({ custom: { rating: ratingNr } });

                      let newSvg = '<svg width="100%" height="100%" viewBox="0 0 881 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">\
                        <g transform="matrix(1,0,0,1,-634.728,-382.568)">\
                            <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating1Color + ';"/>\
                        </g>\
                        <g transform="matrix(1,0,0,1,-447.914,-382.568)">\
                            <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating2Color + ';"/>\
                        </g>\
                        <g transform="matrix(1,0,0,1,-261.961,-382.568)">\
                            <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill: ' + rating3Color + ';"/>\
                        </g>\
                        <g transform="matrix(1,0,0,1,-76.0238,-382.568)">\
                            <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating4Color + ';"/>\
                        </g>\
                        <g transform="matrix(1,0,0,1,109.853,-382.568)">\
                            <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating5Color + ';"/>\
                        </g>\
                    </svg>';

                    let url = "data:image/svg+xml;base64," + window.btoa(newSvg);

                        element.set({ src: url });
                  }}
  
                  onKeyUp={(e) => {
                      element.set({ custom: { rating: document.querySelector("#lf-text-field-text").value } });
                  }}
              />
          </div>

          <SvgToolbar store={store} elements={store.selectedElements} />
      </div>
  
    );
  });

const CustomImageToolbar = observer(({ store }) => {
  const element = store.selectedElements[0];

  let fields;

  if (element.custom.screenshot) {
    let popoverContent=()=>{
        return(
            <div>
            <Popover2>
                <Dropdown.Menu >
                    <Dropdown.Item onClick={() => addTokenRating('[business.website]')}>[business.website]</Dropdown.Item>
                    <Dropdown.Item onClick={() => addTokenRating('[business.gmb]')}>[business.gmb]</Dropdown.Item>
                    <Dropdown.Item onClick={() => addTokenRating('[competitor.website]')}>[competitor.website]</Dropdown.Item>
                    <Dropdown.Item onClick={() => addTokenRating('[competitor.gmb]')}>[competitor.gmb]</Dropdown.Item>
                </Dropdown.Menu>
            </Popover2>
            </div>
      );
    }

    fields = 
        <div className="full-width lf-text-input-wrapper">
            <div className="float-right" style={{marginTop: '-36px', marginBottom: '0px', marginRight: '0px'}}>
                <Popover2 content={popoverContent()} placement="bottom">
                    <a href="#">+ Add Token</a>
                </Popover2>
            </div>
            <input id="lf-text-field-text" type="text" className="lf-text-field" defaultValue={element.custom.website} placeholder="https://example.com"
                onInput={(e) => {
                    element.set({ custom: { screenshot: true, device: element.custom.device, website: document.querySelector("#lf-text-field-text").value} });
                }}

                onChange={(e) => {
                    element.set({ custom: { screenshot: true, device: element.custom.device, website: document.querySelector("#lf-text-field-text").value} });
                }}

                onKeyUp={(e) => {
                    element.set({ custom: { screenshot: true, device: element.custom.device, website: document.querySelector("#lf-text-field-text").value} });
                }}

                onBlur={(e) => {
                    if (document.querySelector("#lf-text-field-text").value.substring(0, 4) == "http") {
                        element.set({ src: "", custom: { screenshot: true, device: element.custom.device, website: document.querySelector("#lf-text-field-text").value} });
                        
                        axios.post(RAVU_API_URL + '/personalization-images/generate-screenshot', {
                            website: document.querySelector("#lf-text-field-text").value,
                            device: element.custom.device
                        }).then(function(response) {
                            console.log('response', response)
                            element.set({ src: response.data.screenshot_src });
                        })
                    }
                }}
            />

            <select id="lf-screenshot-type-selection" className="lf-text-field"
                defaultValue={element.custom.device}
                onChange={(e) => {
                    element.set({ custom: { screenshot: true, device: document.querySelector('#lf-screenshot-type-selection').value, website: element.custom.website} });
                }}
            >
                <option value="desktop">Desktop</option>
                <option value="mobile">Mobile</option>
                <option value="tablet">Tablet</option>
            </select>
        </div>
  }
  else {
    fields = <Button
        icon="upload"
        style={{ width: '100%' }}
        onClick={() => {
            document.querySelector('#input-file').click();
        }}
        >
        Upload Image
    </Button>
  }

  return (
    <div>
        <label htmlFor="input-file">
          {fields}
          <input
            type="file"
            id="input-file"
            style={{ display: 'none' }}
            onChange={(e) => {
                const { target } = e;
                console.log('upload here...', target.files)

                let reader = new FileReader();

                reader.readAsDataURL(target.files[0]);

                // on reader load somthing...
                reader.onload = () => {
                    try {
                        // Make a fileInfo Object
                        console.log("Called", reader);
                        let baseURL = reader.result;
                        
                        let selectedImageElement = store.selectedElements[0];

                        selectedImageElement.set({ src: baseURL });
                    }
                    catch (e) {}
                };
                // console.log(fileInfo);
            }}
          />
        </label>

       <ImageToolbar store={store} elements={store.selectedElements} />
    </div>

  );
});

unstable_registerToolbarComponent('text', TextToolbar);
unstable_registerToolbarComponent('svg', CustomSvgToolbar);
unstable_registerToolbarComponent('image', CustomImageToolbar);

export const App = () => {

    let designerLeft = <div className="lf-designer-left">
        <div className="lf-designer-title">Layers</div>
        <SidePanelWrap>
            <SidePanel store={store} sections={sections} defaultSection="layers" />
        </SidePanelWrap>
    </div>;

    let designerElements = <div><div className="lf-designer-title">Elements</div>
            <div className="lf-add-elements">
                <div className="lf-add-element" draggable 
                onDragEnd={(event) => {
                    console.log('event', event)

                    let windowWidth = window.innerWidth;
                    let canvasWidth = window.innerWidth - 500;
                    let canvasInnerWidth = 600;

                    store.activePage.addElement({
                        type: 'text',
                        text: "Your text here...",
                        fill: 'black',
                        x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                        y: event.pageY - 60,
                        contentEditable: false
                    });
                }}>
                <i className="mdi mdi-format-text"></i>
                    <br />
                    Text
                </div>
                <div className="lf-add-element" draggable
                    onDragEnd={(event) => {

                        let windowWidth = window.innerWidth;
                        let canvasWidth = window.innerWidth - 500;
                        let canvasInnerWidth = 600;

                        store.activePage.addElement({
                            type: 'image',
                            x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                            y: event.pageY - 60,
                            width: 324,
                            height: 254,
                            src: 'https://regro-cdn.s3.amazonaws.com/images/change_image.png',
                            custom: {
                                screenshot: false
                            }
                        });
                    }}>
                    <i className="mdi mdi-image-search-outline"></i>
                    <br />
                    Image
                </div>
                <div className="lf-add-element" draggable
                    onDragEnd={(event) => {

                        let windowWidth = window.innerWidth;
                        let canvasWidth = window.innerWidth - 500;
                        let canvasInnerWidth = 600;
                        
                        let elementtttt = store.activePage.addElement({
                            type: 'image',
                            x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                            y: event.pageY - 60,
                            width: 254,
                            height: 252,
                            src: 'https://regro-cdn.s3.amazonaws.com/images/website_placeholder.png',
                            custom: {
                                screenshot: true,
                                device: 'desktop'
                            }
                        });
                    }}>
                    <i className="mdi mdi-image-search-outline"></i>
                    <br />
                    Screenshot
                </div>
                <div className="lf-add-element" draggable
                    onDragEnd={(event) => {

                        let windowWidth = window.innerWidth;
                        let canvasWidth = window.innerWidth - 500;
                        let canvasInnerWidth = 600;
                        
                        store.activePage.addElement({
                            type: 'rect',
                            x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                            y: event.pageY - 60
                        });
                    }}>
                    <i className="mdi mdi-square-outline"></i>
                    <br />
                    Rectangle
                </div>
                <div className="lf-add-element" draggable
                    onDragEnd={(event) => {

                        let windowWidth = window.innerWidth;
                        let canvasWidth = window.innerWidth - 500;
                        let canvasInnerWidth = 600;
                        
                        store.activePage.addElement({
                            type: 'circle',
                            x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                            y: event.pageY - 60
                        });
                    }}>
                    <i className="mdi mdi-circle-outline"></i>
                    <br />
                    Circle
                </div>
                <div className="lf-add-element" draggable
                    onDragEnd={(event) => {

                        let windowWidth = window.innerWidth;
                        let canvasWidth = window.innerWidth - 500;
                        let canvasInnerWidth = 600;

                        let SVG = '<svg width="100%" height="100%" viewBox="0 0 881 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">\
                                <g transform="matrix(1,0,0,1,-634.728,-382.568)">\
                                    <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:#E8DD6F;"/>\
                                </g>\
                                <g transform="matrix(1,0,0,1,-447.914,-382.568)">\
                                    <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:#E8DD6F;"/>\
                                </g>\
                                <g transform="matrix(1,0,0,1,-261.961,-382.568)">\
                                    <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:#E8DD6F;"/>\
                                </g>\
                                <g transform="matrix(1,0,0,1,-76.0238,-382.568)">\
                                    <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:#E8DD6F;"/>\
                                </g>\
                                <g transform="matrix(1,0,0,1,109.853,-382.568)">\
                                    <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:#E6E6E6;"/>\
                                </g>\
                            </svg>';
                        let url = "data:image/svg+xml;base64," + window.btoa(SVG);
                        
                        store.activePage.addElement({
                            type: 'svg',
                            x: event.pageX - 240 - ((canvasWidth - canvasInnerWidth)/2),
                            y: event.pageY - 60,
                            width: 300,
                            height: 50,
                            custom: {
                                rating: 4
                            },
                            src: url
                        });
                    }}>
                    <i className="mdi mdi-star-outline"></i>
                    <br />
                    Rating
                </div>
            </div>
        </div>;
    
    let designerRight = <div className="lf-designer-right">
        <ZoomButtons store={store} />

        <div className="lf-designer-subtitle">
            Properties
        </div>

        <div className="lf-properties">
            <div style={{width: '30%', display: 'inline-block'}}>Width (px)</div>
            <div style={{width: '50%', display: 'inline-block', position: 'relative', zIndex: 11, paddingLeft: '10px'}}>
                <input type="number" autoComplete="off" id="canvasWidth"
                    defaultValue={parseInt(store.width)}
                    onChange={(e) => {
                        store.setSize(parseInt(document.getElementById('canvasWidth').value), parseInt(document.getElementById('canvasHeight').value), false);
                    }}

                    onKeyUp={(e) => {
                        store.setSize(parseInt(document.getElementById('canvasWidth').value), parseInt(document.getElementById('canvasHeight').value), false);
                    }}
                />
            </div>

            <div style={{paddingTop: '10px'}}>
                <div style={{width: '30%', display: 'inline-block'}}>Height (px)</div>
                <div style={{width: '50%', display: 'inline-block', position: 'relative', zIndex: 11, paddingLeft: '10px'}}>
                    <input type="number" autoComplete="off" id="canvasHeight"
                        defaultValue={parseInt(store.height)}
                        onChange={(e) => {
                            store.setSize(parseInt(document.getElementById('canvasWidth').value), parseInt(document.getElementById('canvasHeight').value), false);
                        }}

                        onKeyUp={(e) => {
                            store.setSize(parseInt(document.getElementById('canvasWidth').value), parseInt(document.getElementById('canvasHeight').value), false);
                        }}
                    />
                </div>
            </div>
        </div>

        <hr />

        <div className="lf-designer-subtitle">Layer</div>

        <div className="lf-toolbar">
            <Toolbar store={store} />
        </div>
    </div>;

    let workspaceWidth = "calc: (100% - 500px)";
    let workspaceHeight = "calc(100% - 50px)";

    if (window.location.href.indexOf('preview') != -1) {
        designerLeft = "";
        designerElements = "";
        designerRight = "";
        workspaceWidth = "100%";
        workspaceHeight = "100%";
    }

  return (
    <PolotnoContainer className="polotno-app-container">
        <div className="clearfix"></div>
        {designerLeft}
        <div className="lf-designer-workspace" style={{width: workspaceWidth, height: workspaceHeight}}>
            {designerElements}

            <Workspace backgroundColor="#F2F2F2" pageBorderColor="#F2F2F2" store={store}/>
        </div>
        {designerRight}
        <div className="clearfix"></div>
    </PolotnoContainer>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

window.saveImage = async function() {

    let storeJson = store.toJSON();
    let dataURL = await store.toDataURL();

    window.parent.postMessage({
        type: 'finishSavingImage',
        data: {
            storeJson: storeJson,
            dataURL: dataURL
        }
    }, RAVU_VUE_URL);

    // window.parent.finishSavingImage(storeJson);
}

window.applyStoreJson = function(newStoreJson,selectedBusiness,selectedCompetitor) {
    
    if (window.location.href.indexOf('preview') != -1) {
        let business = selectedBusiness;
        let competitor = selectedCompetitor;
        
        if (typeof(business) != "undefined" && business) {
            newStoreJson = newStoreJson.replace(/\[business.business_name\]/g, business.name);
            newStoreJson = newStoreJson.replace(/\[business.rating\]/g, business.rating);
            newStoreJson = newStoreJson.replace(/\[business.reviews\]/g, business.user_ratings_total);
            newStoreJson = newStoreJson.replace(/\[business.formatted_phone_number\]/g, business.formatted_phone_number);
            newStoreJson = newStoreJson.replace(/\[business.international_phone_number\]/g, business.international_phone_number);
            newStoreJson = newStoreJson.replace(/\[business.phone_number_type\]/g, business.phone_number_type);
            newStoreJson = newStoreJson.replace(/\[business.carrier\]/g, business.carrier);
            newStoreJson = newStoreJson.replace(/\[business.address\]/g, business.address);
            newStoreJson = newStoreJson.replace(/\[business.website\]/g, business.website.split("?")[0]);
            // newStoreJson = newStoreJson.replace(/\[business.gmb\]/g, "https://www.google.com/search?q=" + encodeURIComponent(business.name + " " + business.formatted_address) + "&hl=en");
            newStoreJson = newStoreJson.replace(/\[business.gmb\]/g, business.url);
            newStoreJson = newStoreJson.replace(/\[business.quote\]/g, ((business.on_mobile_using_quote == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[business.chat\]/g, ((business.on_mobile_using_chat == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[business.claimed\]/g, ((business.google_claimed_business == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[business.posts\]/g, ((business.google_posts == 1) ? "Yes" : "No"));
        }

        if (typeof(competitor) != "undefined" && competitor) {
            newStoreJson = newStoreJson.replace(/\[competitor.business_name\]/g, competitor.name);
            newStoreJson = newStoreJson.replace(/\[competitor.rating\]/g, competitor.rating);
            newStoreJson = newStoreJson.replace(/\[competitor.reviews\]/g, competitor.user_ratings_total);
            newStoreJson = newStoreJson.replace(/\[competitor.formatted_phone_number\]/g, competitor.formatted_phone_number);
            newStoreJson = newStoreJson.replace(/\[competitor.international_phone_number\]/g, competitor.international_phone_number);
            newStoreJson = newStoreJson.replace(/\[competitor.phone_number_type\]/g, competitor.phone_number_type);
            newStoreJson = newStoreJson.replace(/\[competitor.carrier\]/g, competitor.carrier);
            newStoreJson = newStoreJson.replace(/\[competitor.address\]/g, competitor.address);
            newStoreJson = newStoreJson.replace(/\[competitor.website\]/g, competitor.website.split("?")[0]);
            newStoreJson = newStoreJson.replace(/\[competitor.gmb\]/g, "https://www.google.com/search?q=" + encodeURIComponent(competitor.name + " " + competitor.formatted_address) + "&hl=en");
            newStoreJson = newStoreJson.replace(/\[competitor.quote\]/g, ((competitor.on_mobile_using_quote == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[competitor.chat\]/g, ((competitor.on_mobile_using_chat == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[competitor.claimed\]/g, ((competitor.google_claimed_business == 1) ? "Yes" : "No"));
            newStoreJson = newStoreJson.replace(/\[competitor.posts\]/g, ((competitor.google_posts == 1) ? "Yes" : "No"));
        }

        store.loadJSON(JSON.parse(newStoreJson), true);
        if(document.getElementById('canvasWidth')){
            document.getElementById('canvasWidth').value = parseInt(store.width);
            document.getElementById('canvasHeight').value = parseInt(store.height);
        }

        store.activePage.children.forEach((element) => {
            if (element.type == 'svg') {
                // console.log('element.custom', element.custom)
                let ratingNr = parseInt(element.custom.rating);

                if (isNaN(ratingNr))
                    ratingNr = 5;
    
                console.log('ratingNr', ratingNr)
    
                let rating1Color = "#E8DD6F";
                let rating2Color = "#E8DD6F";
                let rating3Color = "#E8DD6F";
                let rating4Color = "#E8DD6F";
                let rating5Color = "#E8DD6F";
    
                if (ratingNr < 2) {
                rating2Color = "#E6E6E6";
                rating3Color = "#E6E6E6";
                rating4Color = "#E6E6E6";
                rating5Color = "#E6E6E6";
                }
    
    
                if (ratingNr < 3) {
                rating3Color = "#E6E6E6";
                rating4Color = "#E6E6E6";
                rating5Color = "#E6E6E6";
                }
    
    
                if (ratingNr < 4) {
                rating4Color = "#E6E6E6";
                rating5Color = "#E6E6E6";
                }
    
    
                if (ratingNr < 5) {
                rating5Color = "#E6E6E6";
                }
    
                let newSvg = '<svg width="100%" height="100%" viewBox="0 0 881 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">\
                    <g transform="matrix(1,0,0,1,-634.728,-382.568)">\
                        <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating1Color + ';"/>\
                    </g>\
                    <g transform="matrix(1,0,0,1,-447.914,-382.568)">\
                        <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating2Color + ';"/>\
                    </g>\
                    <g transform="matrix(1,0,0,1,-261.961,-382.568)">\
                        <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill: ' + rating3Color + ';"/>\
                    </g>\
                    <g transform="matrix(1,0,0,1,-76.0238,-382.568)">\
                        <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating4Color + ';"/>\
                    </g>\
                    <g transform="matrix(1,0,0,1,109.853,-382.568)">\
                        <path d="M702.68,382.568L718.721,431.938L770.632,431.938L728.635,462.45L744.677,511.82L702.68,481.308L660.683,511.82L676.724,462.45L634.728,431.938L686.639,431.938L702.68,382.568Z" style="fill:' + rating5Color + ';"/>\
                    </g>\
                </svg>';
    
                let url = "data:image/svg+xml;base64," + window.btoa(newSvg);
    
                element.set({ src: url });
            }
            
            if (element.type == 'image') {
                if (element.custom) {
                    if (element.custom.screenshot) {
                        console.log(business)
                        console.log(business.url)
                        element.set({ src: "", custom: { screenshot: true, device: element.custom.device, website: element.custom.website} });

                        axios.post(RAVU_API_URL + '/personalization-images/generate-screenshot', {
                            website: element.custom.website,
                            device: element.custom.device
                        }).then(function(response) {
                            element.set({ src: response.data.screenshot_src });
                        })
                    }
                }
            }
        });
    }
    else {
        
        store.loadJSON(JSON.parse(newStoreJson), true);
        document.getElementById('canvasWidth').value = parseInt(store.width);
        document.getElementById('canvasHeight').value = parseInt(store.height);
    }
}

try {
    window.parent.imageEditorLoaded();
}
catch (e) {}


window.addEventListener('message', function(event) {
    
    if (event.origin !== RAVU_VUE_URL) {
        // Ignore messages from unknown origins
        return;
    }

    if (event.data === 'saveImage') {
        window.saveImage();
    }else if (event.data.type === 'applyStoreJson') {
        window.applyStoreJson(event.data.newStoreJson);
    }else  if (event.data.type === 'updateData') {
        const selectedBusiness = event.data.data.selectedBusiness;
        const selectedCompetitor = event.data.data.selectedCompetitor;
        window.applyStoreJson(event.data.data.newStoreJson,selectedBusiness,selectedCompetitor);
      }
});

// if (window.location.href.indexOf('preview') != -1) {
//     let business = window.parent.selectedBusiness;
//     // console.log('bizziiziiziziz', business)

//     let competitor = window.parent.selectedCompetitor;
//     // console.log('comptiiziiziziz', competitor)

//     let storeJson = store.toJSON();

//     console.log('storeJson', storeJson);
// }